@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer utilities {
  /* Light mode scrollbar styles */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: theme(colors.scrollbarThumbLight);
    border-radius: 50px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: theme(colors.scrollbarThumbLightHover);
  }

  ::-webkit-scrollbar-thumb:active {
    background: theme(colors.scrollbarThumbLightActive);
  }

  ::-webkit-scrollbar-track {
    background: theme(colors.scrollbarTrackLight);
    border-radius: 50px;
  }

  ::-webkit-scrollbar-track:hover {
    background: theme(colors.scrollbarTrackLightHover);
  }

  ::-webkit-scrollbar-track:active {
    background: theme(colors.scrollbarTrackLightActive);
  }
}
